import Intro from './classes/intro.es6';
import KV from './classes/kv.es6';
import Twitter from './classes/twitter.es6';
import ScrollFunc from './classes/scrollfunc.es6';
import { Tab } from './classes/tab.es6';
import { YtPlayer } from '../../common/classes/ytPlayer.es6';
import { handleYtPlayer } from '../../common/function/handleYtPlayer.es6';

export default class Top {
    constructor() {
        this.intro = new Intro();
        this.kv = new KV();
        this.twitter = new Twitter();
        this.scrollFunc = new ScrollFunc();
        this.YtPlayer = new YtPlayer();
        this.tab = new Tab();
        handleYtPlayer(this.YtPlayer);
    }
}
